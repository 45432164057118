import { NextPage } from "next";
import { useRouter } from "next/router";
import { useEffect } from "react";
import styled from "styled-components";
import PageLoader from "@components/page_loader";

const LoadingContainer = styled.div`
  margin-top: 100px;
`;

const RedirectCF: NextPage = () => {
  const router = useRouter();

  useEffect(() => {
    if (router.asPath !== "/" && !router.asPath.match("/RedirectCF")) {
      router.replace(router.asPath);
    } else {
      router.replace("/");
    }
  }, [router.asPath]);

  return (
    <LoadingContainer>
      <PageLoader />
    </LoadingContainer>
  );
};

export default RedirectCF;
